<template>
  <div class="row">
    <va-modal
      v-model="newCredentialModal"
       size="large"
       class="flex xs12"
      :hide-default-actions="true"
      :fixed-layout="true"
    >
      <div>
        <va-input
          v-model="newCredential.accreditorType"
          placeholder="Accreditor Type"
        />
      </div>
      <div>
        <va-input
          v-model="newCredential.displayName"
          placeholder="Credential Name"
        />
      </div>
      <div>
        <va-input
          v-model="newCredential.value"
          placeholder="Value"
        />
      </div>
      <div>
        <va-toggle
          v-model="newCredential.active"
          label="Active"
        />
      </div>
      <div>
        <va-button
          @click="saveNewCredential"
          color="#85bb65"
        >
          Save New Credential
        </va-button>
      </div>
    </va-modal>
    <va-modal
      v-model="showModal"
      :hide-default-actions="true"
      title="Edit credential"
      :fixed-layout="true"
    >
      <div
        class="modal"
      >
        <div class="mb-4">
          <h3 class="mb-3 underline">
            Accreditor Type
          </h3>
          <div>
            <va-input
              v-model="modalData.accreditorType"
              placeholder="Accreditor Type"
            />
          </div>          
          <h3 class="mb-3 underline">
            Display Name
          </h3>
          <div>
            <va-input
              v-model="modalData.displayName"
              placeholder="Name of the credential"
            />
          </div>
          <h3 class="mb-3 underline">
            Value
          </h3>
          <div>
            <va-input
              v-model="modalData.value"
              placeholder="Value of the credential"
            />
          </div>
          <div>
            <va-toggle
              v-model="modalData.active"
              label="Active"
            />
          </div>
        </div>
      </div>

      <div :slot="'actions'">
        <va-button
          @click="cancelModal"
          color="#cccccc"
        >
          Cancel
        </va-button>
        <va-button
          @click="saveEdit"
          color="#85bb65"
        >
          Save Credential!
        </va-button>
        <va-button
          @click="deleteData"
          :style="{ 'float': 'right' }"
          color="#d20000"
        >
          Delete
        </va-button>
      </div>
    </va-modal> 
    <div class="flex md12">
      <va-card :title="'Credentials'">
        <template #header>
          <div class="custom-div">
            <span>
              Credentials
            </span>
            <va-button
              :disabled="newCredentialModal"
              class="btn-large"
              @click="createCredential()"
            >
              <span v-if="!newCredentialModal">Create Credential</span>
              <span v-if="newCredentialModal">Loading..</span>
            </va-button>
          </div>
        </template>
        <va-data-table
          :fields="fields"
          :data="credentials"
          no-pagination
        >
          <template
            slot="actions"
            slot-scope="props"
          >
            <va-button
              flat
              small
              color="gray"
              @click="edit(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.edit') }}
            </va-button>

            <va-button
              flat
              small
              color="danger"
              @click="remove(props.rowData)"
              class="ma-0"
            >
              {{ $t('tables.delete') }}
            </va-button>
          </template>
        </va-data-table>
  
      </va-card>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase';
import {OrbitSpinner} from 'epic-spinners';

export default {
  name: 'Credentials',
  data: function() {
    return {
      loading: false,
      credentials: [],
      newCredentialModal: false,
      newCredential: {
        active: false,
        accreditorType: null,
        displayName: null,
        value: null
      },
      modalData: {},
      showModal: false,
    };
  },
  components: {
    OrbitSpinner,
  },
  async created() {
    this.getCredentials();
  },
  methods: {
    async getCredentials() {
      this.credentials = [];
      const credentials = firebase.firestore().collection('credentials');

      credentials.get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          data.id = doc.id;
          this.credentials.push(data);          
        });
      });
      
    },
    createCredential() {
      this.newCredentialModal = true;
    },
    deleteData() {
      this.remove(this.modalData);
    },
    remove(credential) {
      this.$swal.fire({
        title: 'Are you sure you want to delete <u>' + credential.displayName + '</u>?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false;
          firebase.firestore().collection('credentials').doc(credential.id).delete().then(() => {
            this.getCredentials();
          });
        }
      });
    },
    cancelModal() {
      this.showModal = false;
    },
    edit(credential) {
      this.modalData = credential;
      this.showModal = true;
    },
    saveEdit() {
      const credential = firebase.firestore().collection('credentials').doc(this.modalData.id);
      credential.update({
        accreditorType: this.modalData.accreditorType,
        active: this.modalData.active,
        displayName: this.modalData.displayName,
        value: this.modalData.value,
      }).then(() => {
        this.showModal = false;
        this.getCredentials();
      });
    },
    saveNewCredential() {
      const data = {
        accreditorType: this.newCredential.accreditorType,
        active: this.newCredential.active,
        displayName: this.newCredential.displayName,
        value: this.newCredential.value,

      };
      firebase.firestore().collection('credentials').add(data).then(() => {
        this.newCredentialModal = false;
        this.getCredentials();
      });
    },
  },
  computed: {
    fields() {
      return [{
        name: 'accreditorType',
        title: 'Accreditor Type',
      }, {
        name: 'active',
        title: 'Active',
      }, {
        name: 'displayName',
        title: 'Display Name',
      }, {
        name: 'value',
        title: 'Value',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }];
    },
  },

};
</script>

<style scoped>
.btn-large {
  width: 25%;
  margin-top: 20px;
}
.custom-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>

